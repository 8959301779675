import React from "react";
import Header from "../components/header/header.js";
import ReadMore from "../components/readMore/readMore.js";

const Hospitality = () => {
  return (
    <>
      <Header
        title="La Suite Hospitality"
        metaDescription="Con noi le recensioni positive saranno assicurate, insieme ad un aumento della brand reputation!"
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <span className="green bold">
              Con noi le recensioni positive saranno assicurate, insieme ad un
              aumento della brand reputation!
            </span>
            <p className="description">
              <br />
              <br />
              Offri al tuo cliente un servizio trasversale di côncierge che lo
              accompagnerà dal momento della prenotazione sino al check-out,
              offrendo anche la possibilità di checkIN online.
              <br />
              <br />
              Attira i clienti e fidelizzali sfruttando il WiFi e i suoi
              strumenti di promozione e comunicazione.
              <br />
              <br />
              Offri una navigazione sicura, e un’assistenza durante il
              soggiorno; sarai in grado di monitorare l’umore del tuo ospite e
              di far fronte alle sue esigenze.
            </p>
            <div className="section" />
          </div>
        </div>
        <div className="columns invert">
          <div className="column">
            <p className="description">
              <b>Connect@You</b> è un moderno servizio di hotspot grazie al quale
              potrai offrire ai tuoi clienti una connessione WiFi gratuita,
              facile e sicura, garantendo la possibilità di essere in rete con
              pochi click, mettendoti al riparo dalla normativa vigente.
              Connect@You è infatti compliant al nuovo GDPR Regolamento (UE)
              2016/679.
            </p>
            <div className="section" />
            <ReadMore
              to="https://connectatyou.it/"
              text="Scopri di più"
              externalLink={true}
            />
          </div>
          <div className="column is-offset-2">
            <img
              src="https://static.acmeproduzioni.it/connectatyou/logo.svg"
              alt="Logo Connect@You"
            />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <img
              src="https://static.acmeproduzioni.it/eucleia/logo.svg"
              alt="Logo Eucleia"
            />
          </div>
          <div className="column is-offset-2">
            <p className="description">
              <b>Eucleia</b> è un{" "}
              <b>
                software in cloud che consente di migliorare la tua reputazione
                online{" "}
              </b>
              e di abbattere di oltre il 70% le recensioni negative in modo
              semplice, attraverso l’invio automatico al tuo cliente di mail e
              sms.
              <br />
              Lo sapevi che la soddisfazione del tuo cliente genera pubblicità?
              Sin dal primo click crea con esso un rapporto privilegiato.
              <br />
              <b>Mission della tua attività: un cliente soddisfatto!</b>
            </p>
            <div className="section" />
            <ReadMore
              to="https://eucleia.app/"
              text="Scopri di più"
              externalLink={true}
            />
          </div>
        </div>
        <div className="section" />
      </div>
    </>
  );
};

export default Hospitality;
